<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "KanbanView.EditKanban",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && kanbanData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && kanbanData.isOk"
    class="form-kanban-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("KanbanView.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="kanbanData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "KanbanView.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          v-model="kanbanData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="Description" class="form-label required">{{
          $t(
            "KanbanView.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeDescription"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedDescription"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="KanbanColumnField" class="form-label required">{{
          $t(
            "KanbanView.KanbanColumnField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeKanbanColumnField"
          :selectedData="selectedKanbanColumnField"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryKanbanViewableFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="HeaderField" class="form-label required">{{
          $t(
            "KanbanView.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeHeaderField"
          :selectedData="selectedHeaderField"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="PageLayout" class="form-label required">{{
          $t(
            "KanbanView.PageLayout",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditPageLayout/{0}&pageLayoutId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangePageLayout"
          :selectedData="selectedPageLayout"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryPageLayouts?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="CalculatedField" class="form-label required">{{
          $t(
            "KanbanView.CalculatedField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeCalculatedField"
          :selectedData="selectedCalculatedField"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col col-md-4">
        <label for="CalculatedFieldSuffix" class="form-label"
          >{{
            $t(
              "KanbanView.CalculatedFieldSuffix",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="CalculatedFieldSuffix"
          v-model="kanbanData.calculatedFieldSuffix"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col col-md-4">
        <label for="AggregateFunction" class="form-label">{{
          $t(
            "KanbanView.AggregateFunction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedAggregateFunction"
          :allowEmpty="false"
          :data="aggregateFunctionData"
          @onChange="onChangeForAggregateFunction"
        />
      </div>
      <div class="col col-md-4">
        <label for="CountSuffix" class="form-label"
          >{{
            $t(
              "KanbanView.CountSuffix",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="CountSuffix"
          v-model="kanbanData.countSuffix"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewKanban",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      kanbanData: {
        customObjectPublicId: this.$route.params.customObjectId,
        publicId: this.$route.params.kanbanId,
      },
      detailRequestEnd: false,
      selectedKanbanColumnField: [],
      selectedDescription: [],
      selectedCalculatedField: [],
      selectedHeaderField: [],
      selectedPageLayout: [],
      selectedAggregateFunction: [],
      selectValueReset: false,
      nullObject: [{ key: null, value: null }],
      aggregateFunctionData: [
        {
          key: "1",
          value: this.$t(
            "KanbanView.AggregateSum",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "KanbanView.AggregateAverage",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "KanbanView.AggregateCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "KanbanView.AggregateMax",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "KanbanView.AggregateMin",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeDescription: function (selected) {
      this.kanbanData.descriptionFieldPublicId = selected.key;
    },
    onChangeKanbanColumnField: function (selected) {
      this.kanbanData.kanbanColumnFieldPublicId = selected.key;
    },
    onChangeHeaderField: function (selected) {
      this.kanbanData.headerFieldPublicId = selected.key;
    },
    onChangePageLayout: function (selected) {
      this.kanbanData.pageDesignerPublicId = selected.key;
    },
    onChangeCalculatedField: function (selected) {
      this.kanbanData.calculatedFieldPublicId = selected.key;
    },
    onChangeForAggregateFunction: function (selected) {
      this.kanbanData.aggregateFunctionId = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-kanban-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-KanbanViewEdit", { ...this.kanbanData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/Kanban/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getAggregateFunctionName(valueTypeId) {
      var text = this.$t(
        "KanbanView.AggregateSum",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (valueTypeId == 2) {
        text = this.$t(
          "KanbanView.AggregateAverage",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 3) {
        text = this.$t(
          "KanbanView.AggregateCount",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 4) {
        text = this.$t(
          "KanbanView.AggregateMax",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 5) {
        text = this.$t(
          "KanbanView.AggregateMin",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getKanban() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-KanbanViewGet?id={0}&coId={1}",
            this.$route.params.kanbanId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.kanbanData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (!String.isNullOrWhiteSpace(response.data.aggregateFunctionId)) {
              this.selectedAggregateFunction.push({
                key: response.data.aggregateFunctionId.toString(),
                value: this.getAggregateFunctionName(
                  response.data.aggregateFunctionId
                ),
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.kanbanData.descriptionFieldName
              ) &&
              !String.isNullOrWhiteSpace(
                this.kanbanData.descriptionFieldPublicId
              )
            ) {
              this.selectedDescription.push({
                key: this.kanbanData.descriptionFieldPublicId,
                value: this.kanbanData.descriptionFieldName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(this.kanbanData.headerFieldName) &&
              !String.isNullOrWhiteSpace(this.kanbanData.headerFieldPublicId)
            ) {
              this.selectedHeaderField.push({
                key: this.kanbanData.headerFieldPublicId,
                value: this.kanbanData.headerFieldName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(
                this.kanbanData.kanbanColumnFieldName
              ) &&
              !String.isNullOrWhiteSpace(
                this.kanbanData.kanbanColumnFieldPublicId
              )
            ) {
              this.selectedKanbanColumnField.push({
                key: this.kanbanData.kanbanColumnFieldPublicId,
                value: this.kanbanData.kanbanColumnFieldName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(this.kanbanData.pageDesignerName) &&
              !String.isNullOrWhiteSpace(this.kanbanData.pageDesignerPublicId)
            ) {
              this.selectedPageLayout.push({
                key: this.kanbanData.pageDesignerPublicId,
                value: this.kanbanData.pageDesignerName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(this.kanbanData.calculatedFieldName) &&
              !String.isNullOrWhiteSpace(
                this.kanbanData.calculatedFieldPublicId
              )
            ) {
              this.selectedCalculatedField.push({
                key: this.kanbanData.calculatedFieldPublicId,
                value: this.kanbanData.calculatedFieldName,
              });
            }
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getKanban();
  },
};
</script>
